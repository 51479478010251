import React from 'react';

import DalabGrid from './components/DalabGrid';
import DalabCard from './components/DalabCard';

import { publicUrl } from './utils';


export const openings = [
  //   {
  //       title: 'PhD fellowship, using machine learning in Astrophysics',
  //       startDate: 'Spring 2023',
  //       description: 'We invite applications for a PhD fellowship on using machine learning in order to design and build innovative emulators for complex physics simulations. This line of research is embedded into the larger context of AI for science. The methodological focus is on adaptive numerical methods and on using reinforcement learning for optimal allocation of computational resources. Concretely, we aim (i) to explore parameter spaces in stellar-binary population simulations, (ii) to embed small-scale process emulators into large-scale simulations, and (iii) to accelerate simulations of intermediate-mass blackhole formation. For more information, click the following link.',
  //   url: 'https://academicjobsonline.org/ajo/jobs/24254',
  // },
  //   {
  //       title: 'Four GW-Learn PhD Fellowships in gravitational-wave astronomy, astrophysical simulations, cosmology, and machine learning.',
  //       startDate: 'Spring 2023',
  //       description: 'We invite applications for four PhD fellowships as part of GW-Learn, a multidisciplinary research program on Gravitational-Wave (GW) science, across Swiss universities. GW-Learn aims at developing techniques, theories, algorithms, and simulations that will allow for optimal knowledge acquisition from the next-generation GW observatories, such as the Einstein Telescope (ET) and the Laser Interferometer Space Antenna (LISA). The successful candidates will have the opportunity to work with a team of world-leading experts in GW astrophysics, Machine Learning, GW data analysis, Cosmology, Fundamental Gravity, and Experimental Physics, including Profs. Tassos Fragos, Michele Maggiore, Corinne Charbonnel, Toni Riotto, and Steven Schramm from University of Geneva, Profs. Lucio Mayer, Prasenjit Saha, and Philippe Jetzer from the University of Zurich, Profs. Thomas Hofmann and Domenico Giardini from ETH-Zurich, Dr. Elena Cuoco from the European Gravitational Observatory, and Dr. Jonathan Gair from the Max-Planck Institute for Gravitational Physics (Albert Einstein Institute).',
  //   url: 'https://jobregister.aas.org/ad/9216a174',
  // },
];

export const OpeningCard = opening =>
  <DalabCard title={opening.title} subheader={opening.startDate} image={opening.image} text={opening.description} url={opening.url} />

export default () =>
  <DalabGrid data={openings} card={OpeningCard} emptyMsg='Currently we do not have any open positions.' />
