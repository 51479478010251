import React from 'react';
import { 
  Paper,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
}from '@material-ui/core';

export default () =>
  <>
    <iframe title="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2701.7212566884455!2d8.5487234!3d47.378358600000006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa0a661cef33b%3A0x747e374bf24bd27f!2sUniversit%C3%A4tstrasse+6%2C+8006+Z%C3%BCrich%2C+Schweiz!5e0!3m2!1sde!2sde!4v1421603256465" width="600" height="300" frameBorder="0" style={{height: '20em', width: '100%'}}></iframe>
    <Paper>
      <Box p={2}>
        <Typography variant='h4' gutterBottom>
          Contact
        </Typography>
        <Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>E-MAIL </TableCell>
                <TableCell className='text-reverse'>
                  hc.zhte.fni@akytom.aniluap                  
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PHONE </TableCell>
                <TableCell className='text-reverse'>
                  9775 236 44 14+                  
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ADDRESS </TableCell>
                <TableCell>
                  CAB F 42.1<br/>
                  Universitätstrasse 6<br/>
                  8006 Zürich<br/>
                  Switzerland
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br/>
          The Department of Computer Science provides a <a href="http://www.inf.ethz.ch/department/location.html" target="_blank" rel="noopener noreferrer">detailed description</a> on how to find us.<br/>
        </Typography>
      </Box>
    </Paper>
  </>
