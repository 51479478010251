import React from 'react';

import DalabGrid from './components/DalabGrid';

import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Button,
} from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { openInNewTab } from './utils';


export const semesters = [
    {
    title: 'Fall Semester 2024',
    lectures: [
      {
        name: 'Deep Learning',
        type: 'Lecture',
        course_catalog_url: 'https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=182386&semkez=2024W&ansicht=LEHRVERANSTALTUNGEN&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2024/DeepLearning'
      },
    ]
  },
  {
    title: 'Spring Semester 2024',
    lectures: [
      {
        name: 'Information Theory',
        type: 'Lecture',
        course_catalog_url: 'https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=176520&semkez=2024S&ansicht=LEHRVERANSTALTUNGEN&lang=en',
        details_url: ''
      },
      {
        name: 'Computational Intelligence Lab',
        type: 'Lecture',
        course_catalog_url: 'https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?semkez=2024S&ansicht=ALLE&lerneinheitId=176863&lang=en',
        details_url: 'https://moodle-app2.let.ethz.ch/course/view.php?id=22092'
      },
    ]
  },
  {
    title: 'Fall Semester 2023',
    lectures: [
      {
        name: 'Deep Learning',
        type: 'Lecture',
        course_catalog_url: 'https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?semkez=2023W&ansicht=ALLE&lerneinheitId=172437&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2023/DeepLearning'
      },
    ]
  },
  {
    title: 'Spring Semester 2023',
    lectures: [
      {
        name: 'Generative Visual Models',
        type: 'Seminar + Independent Project',
        course_catalog_url: 'https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?semkez=2023S&ansicht=KATALOGDATEN&lerneinheitId=171538&lang=de',
        details_url: 'https://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?semkez=2023S&ansicht=ALLE&lerneinheitId=171538&lang=en'
      },
      {
        name: 'Information Theory',
        type: 'Lecture',
        course_catalog_url: 'https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=171419&semkez=2023S&ansicht=LEHRVERANSTALTUNGEN&lang=en',
        details_url: 'https://moodle-app2.let.ethz.ch/course/view.php?id=19758'
      },
      {
        name: 'Computational Intelligence Lab',
        type: 'Lecture',
        course_catalog_url: 'https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=167246&semkez=2023S&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2023/CIL/'
      },
    ]
  },
  {
    title: 'Fall Semester 2022',
    lectures: [
      {
        name: 'Deep Learning',
        type: 'Lecture',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?semkez=2022W&ansicht=KATALOGDATEN&lerneinheitId=162634&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2022/DeepLearning'
      },
    ]
  },
  {
    title: 'Spring Semester 2022',
    lectures: [
      {
        name: 'Computational Intelligence Lab',
        type: 'Lecture',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?semkez=2022S&ansicht=ALLE&lerneinheitId=157374&lang=de',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2022/CIL/'
      },
    ]
  },
  {
    title: 'Fall Semester 2021',
    lectures: [
      {
        name: 'Deep Learning',
        type: 'Lecture',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?semkez=2021W&ansicht=KATALOGDATEN&lerneinheitId=148055&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2021/DeepLearning'
      },
    ]
  },
  {
    title: 'Spring Semester 2021',
    lectures: [
      {
        name: 'Computational Intelligence Lab',
        type: 'Lecture',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?semkez=2021S&ansicht=ALLE&lerneinheitId=149117&lang=de',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2021/CIL'
      },
    ]
  },
  {
    title: 'Fall Semester 2020',
    lectures: [
      {
        name: 'Deep Learning',
        type: 'Lecture',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=140940&semkez=2020W&ansicht=KATALOGDATEN&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2020/DeepLearning'
      },
    ]
  },
  {
    title: 'Spring Semester 2020',
    lectures: [
      {
        name: 'Computational Intelligence Lab',
        type: 'Lecture',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=135225&semkez=2020S&ansicht=KATALOGDATEN&lang=de',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2020/CIL'
      },
    ]
  },
  {
    title: 'Fall Semester 2019',
    lectures: [
      {
        name: 'Deep Learning',
        type: 'Lecture',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=132522&semkez=2019W&ansicht=KATALOGDATEN&lang=de',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2019/DeepLearning'
      },
      {
        name: 'Bachelor Seminar: Machine Learning',
        type: 'Seminar',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=137579&semkez=2019W&ansicht=KATALOGDATEN&lang=de',
        details_url: 'https://docs.google.com/spreadsheets/d/1MZ0fjlfyD8DJeCImlNbr6Wl4FkevKVfeIRodxtX13cI/edit#gid=0'
      }
    ]
  },
  {
    title: 'Spring Semester 2019',
    lectures: [
      {
        name: 'Computational Intelligence Lab',
        type: 'Lecture',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=127561&semkez=2019S&ansicht=KATALOGDATEN&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2019/CIL'
      },
      {
        name: 'Natural Language Understanding',
        type: 'Lecture',
        course_catalog_url: 'http://www.vvz.ethz.ch/lerneinheitPre.do?semkez=2019S&lerneinheitId=127311&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2019/NLU'
      }
    ]
  },
  {
    title: 'Fall Semester 2018',
    lectures: [
      {
        name: 'Deep Learning',
        type: 'Lecture',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=124873&semkez=2018W&ansicht=KATALOGDATEN&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2018/DeepLearning'
      }
    ]
  },
  {
    title: 'Spring Semester 2018 ',
    lectures: [
      {
        name: 'Computational Intelligence Lab',
        type: 'Lecture',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=120106&semkez=2018S&ansicht=KATALOGDATEN&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2018/CIL'
      },
      {
        name: 'Natural Language Understanding',
        type: 'Lecture',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=119655&semkez=2018S&ansicht=KATALOGDATEN&lang=de',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2018/NLU'
      }
    ]
  },
  {
    title: 'Fall Semester 2017',
    lectures: [
      {
        name: 'Deep Learning',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=117484&semkez=2017W&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2017/DeepLearning'
      },
      {
        name: 'Bedeutung und Information',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=117938&semkez=2017W&lang=de',
        details_url: 'https://moodle-app2.let.ethz.ch/enrol/index.php?id=3600'
      }
    ]
  },
  {
    title: 'Spring Semester 2017 ',
    lectures: [
      {
        name: 'Advanced Topics in Information Retrieval and NLP',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=112114&semkez=2017S&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2017/Advanced-Topics-In-IR-And-NLP'
      },
      {
        name: 'Computational Intelligence Lab',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=112237&semkez=2017S&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2017/CIL'
      },
      {
        name: 'Natural Language Understanding',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?semkez=2017S&ansicht=LEHRVERANSTALTUNGEN&lerneinheitId=111765&lang=de',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2017/NLP'
      }
    ]
  },
  {
    title: 'Fall Semester 2016 ',
    lectures: [
      {
        name: 'Deep Learning',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=110597&semkez=2016W&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2016/DeepLearning'
      },
      {
        name: 'Information Retrieval',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=109467&semkez=2016W&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2016/Information-Retrieval'
      }
    ]
  },
  {
    title: 'Spring Semester 2016 ',
    lectures: [
      {
        name: 'Introduction to Natural Language Processing',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=104004&semkez=2016S&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2016/NLP'
      },
      {
        name: 'Computational Intelligence Lab',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=104319&semkez=2016S&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2016/CIL'
      }
    ]
  },
  {
    title: 'Fall Semester 2015 ',
    lectures: [
      {
        name: 'Information Retrieval',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?semkez=2015W&lang=en&lerneinheitId=101414',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2015/Information-Retrieval'
      },
      {
        name: 'Advanced Topics in Machine Learning',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=100981&semkez=2015W&lang=en',
        details_url: 'https://docs.google.com/document/d/1wTE890wMbE_dJj4VjNphIEO_5brJImB-9aSxi2N8uSo/edit?pli=1'
      }
    ]
  },
  {
    title: 'Spring Semester 2015 ',
    lectures: [
      {
        name: 'Computational Intelligence Lab',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=96770&semkez=2015S&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2015/CIL'
      },
      {
        name: 'Introduction to Natural Language Processing',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=96317&semkez=2015S&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2015/NLP'
      }
    ]
  },
  {
    title: 'Fall Semester 2014 ',
    lectures: [
      {
        name: 'Information Retrieval',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?semkez=2015W&lang=en&lerneinheitId=101414',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2015/Information-Retrieval'
      },
      {
        name: 'Advanced Topics in Machine Learning',
        course_catalog_url: 'http://vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=100981&semkez=2015W&lang=en',
        details_url: null
      },
      {
        name: 'Big Data',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=92561&semkez=2014W&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2014/Big-Data'
      },
      {
        name: 'Probabilistic Graphical Models for Image Analysis',
        course_catalog_url: 'http://www.vvz.ethz.ch/Vorlesungsverzeichnis/lerneinheitPre.do?lerneinheitId=92268&semkez=2014W&lang=en',
        details_url: 'http://www.da.inf.ethz.ch/teaching/2014/Probabilistic-Graphical-Models-for-Image-Analysis'
      }
    ]
  }
];

const useStyles = makeStyles(theme => ({
  detailsButton: {
    marginLeft: theme.spacing(2),
  },
}));

export function LectureCard(lecture) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Card>
      <Box height={theme.spacing(25)} display='flex' flexDirection='column' justifyContent='space-between'>
        <CardHeader title={lecture.name} subheader={lecture.type} />
        <CardContent>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='outlined'
              onClick={() => openInNewTab(lecture.course_catalog_url)}>
              Catalogue
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              className={classes.detailsButton}
              onClick={() => openInNewTab(lecture.details_url)}>
              Details
            </Button>
          </Box>
        </CardContent>
      </Box>
    </Card>
  )
}

export default () =>
  <>
    {semesters.map(({ title, lectures }, idx) =>
      <React.Fragment key={idx}>
        <Box mt={2}>
          <Typography variant='h5'>{title}</Typography>
        </Box>
        <DalabGrid data={lectures} card={LectureCard} />
      </React.Fragment>
    )}
  </>
