import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import PublicIcon from '@material-ui/icons/Public';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import MailIcon from '@material-ui/icons/Mail';
import SchoolIcon from '@material-ui/icons/School';
import BlogIcon from '@material-ui/icons/Create';

import Home from './Home';
import homeBanner from './images/banners/header-panel.jpg';
import Blog from './Blog';
import blogBanner from './images/banners/header-room.jpg';
import People from './People';
import peopleBanner from './images/group.png';
import Teaching from './Teaching';
import teachingBanner from './images/banners/header-gaming.png';
import Publications from './Publications';
import publicationsBanner from './images/banners/header-exit.jpg';
import News from './News';
import newsBanner from './images/banners/header-dog.jpg';
import Projects from './Projects';
import projectsBanner from './images/banners/header-thinking.jpg';
import Openings from './Openings';
import openingsBanner from './images/banners/header-lamp.jpg';
import Contact from './Contact';

export default [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: Home,
    icon: HomeIcon,
    banner: homeBanner,
  },
  {
    path: '/people',
    name: 'People',
    component: People,
    icon: PersonIcon,
    banner: peopleBanner,
  },

  {
    path: '/publications',
    name: 'Publications',
    component: Publications,
    icon: FindInPageIcon,
    banner: publicationsBanner,
  },

  // {
  //   path: '/blog_post',
  //   name: 'Blog',
  //   component: Blog,
  //   banner: blogBanner,
  //   icon: BlogIcon,
  // },

  {
    path: '/teaching',
    name: 'Teaching',
    component: Teaching,
    icon: SchoolIcon,
    banner: teachingBanner,
  },

  {
    path: '/news',
    name: 'News',
    component: News,
    icon: PublicIcon,
    banner: newsBanner,
  },

  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    icon: AccountTreeIcon,
    banner: projectsBanner,
  },

  {
    path: '/openings',
    name: 'Openings',
    component: Openings,
    icon: AddIcon,
    banner: openingsBanner,
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    icon: MailIcon,
  },


];

