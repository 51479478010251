import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router} from "react-router-dom";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './App';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#0066ff',
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: 'blue',
      }
    },
    MuiCardHeader: {
      title: {
        fontSize: '175%',
      }
    }
  },
});

ReactDOM.render((
  <Router basename={process.env.PUBLIC_URL}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Router>
), document.getElementById('root'));
