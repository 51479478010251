import React from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  CardMedia,
  Box,
} from '@material-ui/core';

import Markdown from './Markdown';

import { openInNewTab } from '../utils';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardImage: {
    width: '100%',
    maxHeight: theme.spacing(25),
    objectFit: 'contain',
    padding: '0 1em',
  },
}));


export default function({
  title, 
  titleTypographyProps,
  subheader, 
  subheaderTypographyProps,
  image, 
  text, 
  markdown,
  url,
  cardHeight,
  children,
}){
  const classes = useStyles();
  const theme = useTheme();

  const content = <Box height={cardHeight && theme.spacing(cardHeight)}>
          <CardHeader title={title} titleTypographyProps={titleTypographyProps} subheader={subheader} subheaderTypographyProps={subheaderTypographyProps}/>
          {image && <CardMedia component='img' className={classes.cardImage} image={process.env.PUBLIC_URL + '/' + image}/>}
          <CardContent>
            {text && 
            <Typography>
              {text}
            </Typography>
            }
            {markdown && <Markdown>{markdown}</Markdown>}
            {children}
          </CardContent>
  </Box>


  return(
    <Card>
      {url ? 
      <CardActionArea onClick={() => openInNewTab(url)} className={classes.card}>
        {content}
      </CardActionArea> 
      : content}
    </Card>
  )
}
