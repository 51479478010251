import React from 'react';
import {
  Box,
  Grid,
  Fade,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  gridItem: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

export default function({data, children, card, emptyMsg}){
  const classes = useStyles();
  return(
    <>
    <Grid container>
      { data.map((el, index) => 
        <Grid item xs={12} md={6} lg={4} key={index}>
          <Fade in timeout={Math.min(500 * index, 3000)}>
            <Box className={classes.gridItem}>
              {card ? card(el) : children(el)}
            </Box>
          </Fade>
        </Grid>
      )}
    </Grid>
    { !data.length && <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
    <Typography align='center' style={{fontSize: 'large', color: '#555'}}>{ emptyMsg || 'Nothing here.' }</Typography>
    </Box>}
  </>
  )
}
