import React from 'react';
import {
  Switch,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import DalabBanner from './components/DalabBanner';

import logoDal from './images/logo-dal.png';
import logoEthz from './images/logo-ethz.png';

import routes from './routes';

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: 2000,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: 0,
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      width: `calc(100vw - ${drawerWidth}px)`,
    },
    '& p' : {
      textAlign: 'justify',
    },
  },
  dalabBanner: {
    width: `calc(100% + 2*${theme.spacing(3)}px)`,
    marginLeft: -theme.spacing(3),
  },
  drawerLogo: {
    width: '100%',
    padding: '1em 5em',
  },
  headerLogo: {
    height: '2.25em',
    [theme.breakpoints.up('sm')]: {
      height: '3em',
    },
    display: 'block',
  },
  navLink: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
  navLinkActive: {
    '& .nav-button':{
      backgroundColor: 'lightgray',
    }
  },
}));

function App(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  console.log(theme);
  const match = useRouteMatch();
  console.log(match);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}/>
      <List>
        {routes.map((route, index) => (
          <NavLink to={route.path} exact={route.exact} onClick={() => setMobileOpen(false)} key={index} className={classes.navLink} activeClassName={classes.navLinkActive}>
            <ListItem button className='nav-button'>
              <ListItemIcon>{route.icon && <route.icon/>}</ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </div>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Box display='flex' justifyContent='space-between' flexWrap='nowrap' width='100%'>
            <Link to='/'>
              <img src={logoDal} alt='' className={classes.headerLogo}/>
            </Link>
            <a href='https://www.ethz.ch' target='_blank' rel='noreferrer noopener'>
              <img src={logoEthz} alt='' style={{padding: '9px 0'}} className={classes.headerLogo}/>
            </a>
          </Box>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
          <div className={classes.toolbar} />
            <Switch>
              {routes.map((route, index) => (
                <Route path={route.path} exact={route.exact} key={index}>
                  <DalabBanner className={classes.dalabBanner} src={route.banner}/>
                  <route.component/>
                </Route>
              ))}
              <Route path='/'>
                {/* this triggers if no other route matches */}
                <Redirect to='/'/>
              </Route>
            </Switch>
          </main>
        </div>
  );
}

export default App;
