import React, { useState } from 'react';
import {
    Paper,
    Typography,
    Card,
    CardHeader,
    CardActionArea,
    Box
  }from '@material-ui/core';
import {
    useHistory,
    useRouteMatch,
    Route,
    Redirect,
  } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import slugify from 'slugify';
import DalabGrid from './components/DalabGrid';
import DalabCard from './components/DalabCard';
import ArticleIcon from '@material-ui/icons/Assignment';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme'
import 'react-pdf/dist/esm/entry.webpack';

// import signal_propagation_transformers_pdf from "./blog_pdfs/signal_propagation_transformers.pdf"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export const blogs = [
    {
      date: '06.06.2022',
      link: 'https://arxiv.org/abs/2206.03126',
      title: 'Signal Propagation in Transformers: Theoretical Perspectives and the Role of Rank Collapse',
      authors: 'Lorenzo Noci, Sotiris Anagnostidis, Luca Biggio, Antionio Orvieto, Sidak Pal Singh, Aurelien Lucchi',
      contact: ['lorenzo.noci@inf.ethz.ch', 'sotirios.anagnostidis@inf.ethz.ch'],
      pdf_file: process.env.PUBLIC_URL + "/assets/blog_signal_propagation_transformers.pdf",
    }
];
  
const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(2),
    },
    cardImage: {
      width: '26em',
      maxHeight: '22em',
      objectFit: 'contain',
      margin: '1em',
    },
  }));

blogs.map(b => b.slug = slugify(b.title, {remove: /[\s]/g}))

function BlogGrid(){

    const history = useHistory();

    function goToBlog(blog){
        history.push(`/blog_post/${blog.slug}`);
    }

    return(
    <DalabGrid data={blogs}>
        {(blog) =>
        <Card>
            <CardActionArea onClick={() => goToBlog(blog)}>
            <DalabCard title={blog.title} text={blog.authors} cardHeight={35}/>
            </CardActionArea>
        </Card>
    }
    </DalabGrid>
    )
}

  
function Blog({blog}){
    const classes = useStyles();
    const [pageNumber, _] = useState(1);

    if (!blog) return <Redirect to='/blog_post'/>
    const infoParts = [
      {title: 'Title', prop: 'title'},
      {title: 'Date', prop: 'date'},
    ]

    return (
      <>
        <Paper className={classes.paper}>
          <Box px={3} py={2}>
            <Box >
              <ListItem>
                <ListItemIcon><a href={blog.link}><ArticleIcon fontSize="large"/></a></ListItemIcon>
                <ListItemText primary={<Typography variant='h6' gutterBottom>{blog.title}</Typography>} variant='h2'/>
              </ListItem>
            </Box>


            <Typography variant='subtitle1' gutterBottom>
              {blog.authors}
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
              Date: {blog.date}
            </Typography>
              
            <Box display='flex' alignItems='flex-start' flexWrap='wrap' mb={4}>
              <Box mt='1em'>
                {blog.contact && 
                <Typography variant='p' gutterBottom>
                  Interested in our work? Contact us at {"\u00a0"}
                    {blog.contact.map((c, i) => ([<a href={`mailto:${ c }`} key={i}>{c}</a>, <nobr>{"\u00a0"}{"\u00a0"}{"\u00a0"}</nobr>]))}
                </Typography>
                }
              </Box>
            </Box>
            
            {/* <div width="100%"> */}
            <SizeMe>
              {({ size }) => (
                <Document file={blog.pdf_file} loading="Loading…" error="Failed to load…">
                  <Page pageNumber={1} width={size.width ? size.width : 1} />
                </Document>
              )}
            </SizeMe>
          </Box>
        </Paper>
      </>
    );
  }
  
export default function(){
    const match = useRouteMatch();
        return (
            <>
                <Route exact path={match.path} component={BlogGrid}/>
                <Route path={`${match.path}/:slug`} render={({match}) =>
                    <Blog blog={blogs.find(p => p.slug === match.params.slug)}/>
            }/>
        </>
    );
}
